<template>
  <div>
    <h3>Water System Reports (Previously Annual Reports)</h3>
    <!--button link to annual report form-->
    <div class="my-3">
    <b-button
      variant="primary"
      @click="$router.push({ name: 'AnnualReport' })"
    >Submit New Water System Report</b-button>
    (Click the button to submit a new water system report)
    </div>
    <div v-if="ar_data == null">
    <h5>List of previously submitted Water System Reports</h5>
    <b-table striped hover :items="ar_list" :fields="fields">
      <template v-slot:cell(id)="data">
        <b-button variant="primary" size="sm" @click="showAR(data.item.id)"
          >View Submission</b-button
        >
      </template>
    </b-table>
    </div>
    <b-card v-if="ar_data">
      <b-card-title>Water System Report Submission</b-card-title>
      <p><b>Note:</b> If board members were submitted, they are not displayed here. You can view archived reports in the <b-link to="pws-files">File Cabinet.</b-link></p>
      <!-- finished viewing -->
      <b-button @click="ar_data = null">Close Report</b-button>
      <b-card-text class="mt-3">
        <p>
          <b-row>
            <b-col cols="3">PWS Name:</b-col
            ><b-col>{{ ar_data.pws_name }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="3">Report Year:</b-col
            ><b-col>{{ ar_data.report_year }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="3">Signed By:</b-col
            ><b-col>{{ ar_data.data_signame }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="3">Submission Date:</b-col
            ><b-col>{{ format_datetime(ar_data.submission_ts) }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="3">Status:</b-col><b-col>{{ get_report_status(ar_data.status) }}</b-col>
          </b-row>
        </p>
        <hr />
        <h5>General Information</h5>
        <p>
          <!-- email -->
          <b-row>
            <b-col cols="3">PWS Email:</b-col
            ><b-col>{{ ar_data.pws_email }}</b-col>
          </b-row>
          <!-- pws address -->
          <b-row>
            <b-col cols="3">PWS Street:</b-col
            ><b-col>{{ ar_data.pws_street }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="3">PWS City:</b-col
            ><b-col>{{ ar_data.pws_city }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="3">PWS State:</b-col
            ><b-col>{{ ar_data.pws_state }}</b-col>
          </b-row>
          <!-- county -->
          <b-row>
            <b-col cols="3">PWS County:</b-col
            ><b-col>{{ ar_data.pws_county }}</b-col>
          </b-row>
          <!-- pop and connections -->
        </p>
        <hr />
        <h5>Population and Connections</h5>
        <p>
          <b-row>
            <b-col cols="3">Population:</b-col
            ><b-col>{{ ar_data.population }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="3">Connections Metered:</b-col
            ><b-col>{{ ar_data.conn_met }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="3">Connections Metered Residential:</b-col
            ><b-col>{{ ar_data.conn_met_res }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="3">Connections Unmetered:</b-col
            ><b-col>{{ ar_data.conn_unmet }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="3">Connections Unmetered Residential:</b-col
            ><b-col>{{ ar_data.conn_unmet_res }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="3">Connections Total:</b-col
            ><b-col>{{ ar_data.conn_total }}</b-col>
          </b-row>
        </p>
        <hr />
        <h5>Administrative Contact</h5>
        <p>
          <!-- name -->
          <b-row>
            <b-col cols="3">Name:</b-col><b-col>{{ ar_data.ac_name }}</b-col>
          </b-row>
          <!-- email -->
          <b-row>
            <b-col cols="3">Email:</b-col><b-col>{{ ar_data.ac_email }}</b-col>
          </b-row>
          <!-- phone -->
          <b-row>
            <b-col cols="3">Phone:</b-col><b-col>{{ ar_data.ac_phone }}</b-col>
          </b-row>
        </p>
        <hr />
        <h5>Legally Responsible Official</h5>
        <p>
          <!-- name -->
          <b-row>
            <b-col cols="3">Name:</b-col
            ><b-col
              >{{ ar_data.lro_first_name }} {{ ar_data.lro_last_name }}</b-col
            >
          </b-row>
          <!-- title -->
          <b-row>
            <b-col cols="3">Title:</b-col><b-col>{{ ar_data.lro_title }}</b-col>
          </b-row>
          <!-- email -->
          <b-row>
            <b-col cols="3">Email:</b-col><b-col>{{ ar_data.lro_email }}</b-col>
          </b-row>
          <!-- phone -->
          <b-row>
            <b-col cols="3">Phone (Bus):</b-col
            ><b-col>{{ ar_data.lro_phone_bus }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="3">Phone (Bus Alt):</b-col
            ><b-col>{{ ar_data.lro_phone_bus_alt }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="3">Phone (Fax):</b-col
            ><b-col>{{ ar_data.lro_phone_fax }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="3">Phone (Home):</b-col
            ><b-col>{{ ar_data.lro_phone_home }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="3">Phone (Mobile):</b-col
            ><b-col>{{ ar_data.lro_phone_mobile }}</b-col>
          </b-row>
          <!-- address -->
          <b-row>
            <b-col cols="3">Street:</b-col
            ><b-col>{{ ar_data.lro_street }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="3">City:</b-col><b-col>{{ ar_data.lro_city }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="3">State:</b-col><b-col>{{ ar_data.lro_state }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="3">Zip:</b-col><b-col>{{ ar_data.lro_zip }}</b-col>
          </b-row>
        </p>
        <hr />
        <h5>Bacti Address</h5>
        <p>
          <!-- name -->
          <b-row>
            <b-col cols="3">Name:</b-col><b-col>{{ ar_data.bact_name }}</b-col>
          </b-row>
          <!-- address -->
          <b-row>
            <b-col cols="3">Street:</b-col><b-col>{{ ar_data.bact_street }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="3">City:</b-col><b-col>{{ ar_data.bact_city }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="3">State:</b-col><b-col>{{ ar_data.bact_state }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="3">Zip:</b-col><b-col>{{ ar_data.bact_zip }}</b-col>
          </b-row>
        </p>
        <hr />
        <h5>Delivery Address</h5>
        <p>
          <!-- address -->
          <b-row>
            <b-col cols="3">Name:</b-col><b-col>{{ ar_data.delivery_name }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="3">Street:</b-col
            ><b-col>{{ ar_data.delivery_street }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="3">City:</b-col><b-col>{{ ar_data.delivery_city }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="3">State:</b-col
            ><b-col>{{ ar_data.delivery_state }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="3">Zip:</b-col><b-col>{{ ar_data.delivery_zip }}</b-col>
          </b-row>
        </p>
        <hr />
        <h5>Operator</h5>
        <p>
          <!-- name -->
          <b-row>
            <b-col cols="3">Name:</b-col
            ><b-col>{{ ar_data.do_first_name }} {{ ar_data.do_last_name }}</b-col>
          </b-row>
          <!-- cert no -->
          <b-row>
            <b-col cols="3">Cert No:</b-col><b-col>{{ ar_data.do_cert_no }}</b-col>
          </b-row>
          <!-- distance -->
          <b-row>
            <b-col cols="3">Distance PWS:</b-col
            ><b-col>{{ ar_data.do_distance_pws }}</b-col>
          </b-row>
        </p>
    </b-card-text>
    </b-card>
  </div>
</template>

<script>
/*
const example_data = {
  "ac_email": "dguillory@natchezwater.com", 
  "ac_name": "DIANE GUILLORY, OFFICE MGR", 
  "ac_phone": "601-445-5521", 
  "bact_city": "NATCHEZ", 
  "bact_name": "WALTER CALVIT", 
  "bact_state": "MS", 
  "bact_street": "P O BOX 1325", 
  "bact_zip": "39121", 
  "conn_met": 7095, 
  "conn_met_res": 5836, 
  "conn_total": 8128, 
  "conn_unmet": 1033, 
  "conn_unmet_res": 878, 
  "data_signame": "Walter Calvit", 
  "data_signature_ts": "Tue, 14 Nov 2023 20:28:10 GMT", 
  "delivery_name": "WALTER CALVIT",
  "delivery_city": "NATCHEZ", 
  "delivery_state": "MS", 
  "delivery_street": "11 CEDAR LN", 
  "delivery_zip": "39120", 
  "do_cert_no": "C03424", 
  "do_distance_pws": "6.00", 
  "do_first_name": "WALTER", 
  "do_last_name": "CALVIT", 
  "id": 6245, 
  "lro_city": "NATCHEZ", 
  "lro_email": "jgamberi@natchezwater.com", 
  "lro_first_name": "JEFF", 
  "lro_last_name": "GAMBERI", 
  "lro_phone_bus": "601-445-5521", 
  "lro_phone_bus_alt": "601-443-7300", 
  "lro_phone_fax": "601-445-0038", 
  "lro_phone_home": "601-597-0917", 
  "lro_phone_mobile": "601-597-0917", 
  "lro_state": "MS", 
  "lro_street": "P O BOX 1325", 
  "lro_title": "SUPERINTENDENT", 
  "lro_zip": "39121", 
  "population": 15792, 
  "pws_city": "NATCHEZ", 
  "pws_county": "ADAMS", 
  "pws_email": "wcalvit@natchezwater.com", 
  "pws_state": "MS", 
  "pws_street": "150 N SHIELDS LANE", 
  "pws_type": "C   ", 
  "status": "R", 
  "submission_ts": "Tue, 14 Nov 2023 20:28:11 GMT"
}
*/

export default {
  name: "AnnualReportStatus",
  data() {
    return {
      ar_list: [],
      status_key: {
        R: "Reviewed",
        N: "Not Reviewed",
        E: "Edit Required",
        F: "Followup Required",
      },
      fields: [
        { key: "id", label: "View" },
        { key: "report_year", label: "Report Year" },
        { key: "data_signame", label: "Signed By" },
        {
          key: "submission_ts",
          label: "Submission Date",
          formatter: this.format_datetime,
        },
        { key: "status", label: "Status", formatter: this.get_report_status },
      ],
      ar_data: null,
    };
  },
  mounted() {
    console.log("AnnualReportStatus.vue mounted");
    this.fetchARList();
  },
  computed: {
    pws_id: function() {
      return this.$store.state.pws.pws_id;
    },
    pws_name: function() {
      return this.$store.state.pws.pws_name;
    },
  },
  methods: {
    get_report_status(status) {
      return this.status_key[status];
    },
    format_datetime(value) {
      if (value === null) {
        return "";
      }
      const date = new Date(value);
      return date.toLocaleString("sv");
    },
    async fetchARList() {
      const path =
        process.env.VUE_APP_API_URI +
        "/annual-report-pws-list?pws_id=" +
        this.pws_id;
      const response = await this.axios.get(path);
      this.ar_list = response.data;
    },
    async showAR(ar_id) {
      console.log("showAR", ar_id);
      const path = process.env.VUE_APP_API_URI + "/annual-report/" + ar_id;
      const response = await this.axios.get(path);
      this.ar_data = response.data;
    },
  },
};
</script>
